/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

function SEO({ description, lang, meta, title, image, url }) {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:image`,
          content: image || require("../img/CW Cloudware.png"),
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:locale`,
          content: "pt_PT",
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: "Cloudware",
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          property: `og:url`,
          content: url,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `pt_PT`,
  meta: [],
  description: ``,
  url: "https://cloudware.pt",
  title: "Cloudware",
  image: require("../img/CW Cloudware.png"),
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  url: PropTypes.string,
}

export default SEO
