import React, { useState } from "react"

import styles from "./styles.module.css"
import { Link } from "gatsby"

const Navbar = ({ path, transparent = false }) => {
  const [open, setOpen] = useState(false)

  const isActiveByUrl = url =>
    path ? (path.includes(url) ? styles.active : {}) : {}

  const links = className => (
    <ul className={className}>
      <li className={isActiveByUrl("/cloudware-business")}>
        <Link to="/cloudware-business">Empresas</Link>
      </li>
      <li className={isActiveByUrl("/toconline")}>
        <Link to="/toconline">Contabilistas</Link>
      </li>
      <li className={isActiveByUrl("/learning-center")}>
        <Link to="/learning-center">Serviços</Link>
      </li>
      <li className={isActiveByUrl("/blog")}>
        <Link to="/blog" target="_blank">
          Blog
        </Link>
      </li>
    </ul>
  )

  return (
    <header
      className={`${styles.header} ${transparent ? styles.transparent : ""}`}
    >
      <nav className={styles.content}>
        <a href="/">
          <img
            src={require(`../../img/${
              transparent ? "Cloudware white" : "CW Cloudware"
            }.png`)}
            alt="Cloudware Logo"
          />
        </a>
        <div className={styles.mobile}>
          {/* eslint-disable-next-line */}
          <img
            src={require("../../img/menu.png")}
            alt="Menu Icon"
            onClick={() => setOpen(!open)}
          />
          {links(open ? "" : styles.hide)}
        </div>
        {links(styles.desktop)}
      </nav>
    </header>
  )
}

export default Navbar
